/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GlobalSearch } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter } from "@roambee/client-utility";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Header(props) {
	const navigate = useNavigate();
	const user = props.user || {};
	const [accounts, setAccounts] = useState([]);
	const [account, setAccount] = useState({});

	const menuItems = [
		{
			lable: "Logout",
			clickHandler: () => {
				// logout from server
				API("POST", "/auth/logout", {}).then(() => {
					navigate("/auth");
				});
			}
		}
	];

	useEffect(() => {
		if (user) {
			API("GET", "/accounts?quick_view=true&all=true")
				.then((result) => {
					if (result.rows && result.rows.length) {
						setAccounts(result.rows);
					}
					if (user && user.account && user.account.data) {
						setAccount({ label: user.account.data?.title, id: user.account?.uuid });
					}
				})
				.catch((error) => {
					if (error.status === 404 && error.message) {
						// eslint-disable-next-line no-console
						console.error(error.message);
					} else {
						// eslint-disable-next-line no-console
						console.error("Error:", error);
						EventEmitter.emit("showSnackbar", {
							id: "error",
							leftIcon: true,
							message: error?.message || "Something went wrong!",
							variant: "error",
							ariaLabel: "close button",
							position: "top-right"
						});
					}
				});
		}
	}, [user]);

	const handleSwitchAccount = async (event, newValue) => {
		const newCustomerDetails = {
			email: "",
			account_uuid: ""
		};

		accounts.forEach((account) => {
			if (account.uuid === newValue.id) {
				newCustomerDetails.email = account.users[0].email;
				newCustomerDetails.account_uuid = account.uuid;
			}
		});
		await API("POST", "/admin/delegate", newCustomerDetails).then(() => {
			window.location.reload();
		});
	};

	return (
		<section id="header">
			<GlobalSearch
				className="header"
				user={user}
				showSearch={false}
				showNotification={false}
				title=""
				menuItems={menuItems}
				accounts={accounts.map((account) => {
					return { label: account.title, id: account.uuid };
				})}
				account={account}
				handleSwitchAccount={handleSwitchAccount}
			/>
		</section>
	);
}

export default Header;
