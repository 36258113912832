import Home from "../components/Home";

const routes = (isLoggedIn) => [
	{
		path: "/",
		element: isLoggedIn && <Home />
	}
];

export default routes;
