/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router";
import "@fontsource/source-sans-pro";

// css
import "./App.scss";

// local files
import routes from "./core/Routes";
import Header from "./components/Header";

// @ts-ignore
import { API, setAccessibleApplications, setAuthUser } from "@roambee/client-utility";
// @ts-ignore
import { Loader } from "@roambee/client-styleguide";

function App() {
	const [user, setUser] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [authenticated, setAuthenticated] = useState(false);

	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		// we have to manually check for route, because from email we are getting static route of reset password link
		// if in future email template got improved, remove this manual checking
		const currentUrl = window.location.pathname;
		if (currentUrl.includes("/reset-password")) {
			// Extract the token from the current URL
			const token = pathname.split("/reset-password/")[1];
			// Redirect to the set-password route with the token
			navigate(`/auth/set-password/${token}`);
		}

		// check if currentUrl has 'auth' in it
		// why? because if it's already in auth, then there is no need to call /me API
		// also checking reset because in case of redirection from email, we do not want to call /me API
		if (!(currentUrl.includes("auth") || currentUrl.includes("reset"))) {
			API("POST", "/user/me", {})
				.then((result) => {
					setUser(result);
					// set apps so that it can be used for route protection (implementation is inside root/src/roambee-client-root.js)
					setAccessibleApplications(result?.applications || []);
					setAuthenticated(true);
					setAuthUser(result);

					const authUser = {
						email: result.email,
						name: result.name
					};

					// Serialize the authUser object to a JSON string
					const authUserJSON = JSON.stringify(authUser);

					// Set the cookie with the JSON string as the value
					const hostname = window.location.hostname;
					let cookieDomain = "; path=/;";

					// Check if the hostname is not localhost, then set the domain for the cookie
					if (!hostname.includes("localhost")) {
						cookieDomain = `; domain=.roambee.com; path=/;`;
					}

					document.cookie = `RoambeeAuthUser=${encodeURIComponent(authUserJSON)}${cookieDomain}`;
				})
				.catch(() => {
					setAuthenticated(false);
					// React Router's <Navigate /> component is designed to work within the context of the current application's routing configuration
					// so it won't navigate to auth using <Navigate to="/auth" />
					navigate("/auth"); // Navigate to the authentication route
				})
				.finally(() => {
					setIsLoading(false); // Regardless of success or error, loading is done
				});
		}
	}, [pathname, navigate]);

	const content = useRoutes(routes(authenticated));

	if (isLoading) {
		return <Loader />;
	}

	return (
		<section id="container">
			{authenticated && (
				<div>
					<Header user={user}></Header>
					{content}
				</div>
			)}
		</section>
	);
}

export default App;
